import React from "react";
import Text from "components/common/Text";
import Space from "components/common/Space";
import HeaderAvatar from "components/common/HeaderAvatar";
import styled, { useTheme } from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { motion } from "framer-motion";
import SEO from "components/layout/SEO";
import { Event, track } from "../../analytics";
import useTestimonials from "../../hooks/useTestimonials";
import Testimonial from "./Testimonial";
import StackGrid from "react-stack-grid";

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;

const TestimonialContainer = styled.div`
  width: 80vw;
`;

const Testimonials = () => {
  const theme = useTheme();
  const accentColor = theme.colors.accent.primary[500];
  const testimonials = useTestimonials();
  const viewportWidth =
    typeof window !== "undefined"
      ? window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      : 600;

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <SEO title="Contact - Devanshi Goradia" />
      <HeaderAvatar
        title="Testimonials for Devanshi"
        subtitle="Clinical Psychologist"
      />
      <TestimonialContainer>
        <StackGrid
          columnWidth={viewportWidth < 500 ? "100%" : 400}
          gutterHeight={30}
          gutterWidth={30}
          itemComponent={"div"}
          duration={200}
        >
          {testimonials.map(({ testimonial }, index) => (
            <Testimonial key={index} {...testimonial} />
          ))}
        </StackGrid>
      </TestimonialContainer>
    </Container>
  );
};

export default Testimonials;
