import React from "react";
import styled from "styled-components";
import Text from "../common/Text";
import Img from "gatsby-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Event, track } from "../../analytics";

export const query = graphql`
  query {
    file(relativePath: { eq: "profile_picture.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 100
          maxHeight: 100
          fit: COVER
          quality: 100
          cropFocus: NORTH
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const ImageContainer = styled(Link)`
  width: 6vw;
  min-width: 80px;
  text-decoration: none;
  transition: transform 300ms ease-in-out;
  padding-right: 1rem;
  &:hover {
    transform: scale(1.1);
    .avatar-image {
      transition: box-shadow 500ms ease-in-out;
      box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.4);
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled(Img)`
  transition: box-shadow 500ms ease-in-out;
  box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
`;

const Container = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  background-color: transparent;
  border: 0px;
  border-radius: 4px;
  text-decoration: none;
`;

const HeaderAvatar = ({ title, subtitle }) => {
  const data = useStaticQuery(query);
  return (
    <Container>
      <ImageContainer to="/">
        <Image
          fluid={data.file.childImageSharp.fluid}
          alt="profile picture"
          fadeIn
          className="avatar-image"
        />
      </ImageContainer>
      <TextContainer>
        <Text size="large" weight="normal" lineHeight="medium">
          {title ?? "Devanshi"}
        </Text>
        <Text size="small" weight="light">
          {subtitle ?? "Clinical Psychologist"}
        </Text>
      </TextContainer>
    </Container>
  );
};

export default HeaderAvatar;
