import { graphql, useStaticQuery } from "gatsby";

const TESTIMONIALS_QUERY = graphql`
  query {
    result: allMdx(
      filter: { fileAbsolutePath: { regex: "/testimonials/i" } }
      sort: { fields: frontmatter___position, order: DESC }
    ) {
      testimonials: nodes {
        testimonial: frontmatter {
          name
          content
        }
      }
    }
  }
`;

const useTestimonials = () => {
  const {
    result: { testimonials },
  } = useStaticQuery(TESTIMONIALS_QUERY);
  return testimonials;
};

export default useTestimonials;
