import React from "react";
import Text from "components/common/Text";
import Space from "components/common/Space";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.accent.primary[100]};
  border-radius: 8px;
  padding: 1rem;
`;

const ContentContainer = styled.div``;

const ProfilePicture = styled.div`
  padding-right: 4px;
`;

const PersonInfoContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
`;

const Testimonial = ({ name, content }) => {
  const theme = useTheme();

  return (
    <Container>
      <ContentContainer>
        <Text size="small">"{content}"</Text>
      </ContentContainer>
      <PersonInfoContainer>
        <ProfilePicture>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 29 29"
            height={30}
            width={30}
          >
            <path
              fill={theme.colors.accent.primary[300]}
              d="M14.5 2A12.514 12.514 0 002 14.5 12.521 12.521 0 0014.5 27a12.5 12.5 0 000-25zm7.603 19.713a8.48 8.48 0 00-15.199.008A10.367 10.367 0 014 14.5a10.5 10.5 0 0121 0 10.368 10.368 0 01-2.897 7.213zM14.5 7a4.5 4.5 0 104.5 4.5A4.5 4.5 0 0014.5 7z"
            />
          </svg>
        </ProfilePicture>
        <Text size="small" color={theme.colors.accent.primary[400]}>
          {name ?? "Anonymous"}
        </Text>
      </PersonInfoContainer>
    </Container>
  );
};

export default Testimonial;
